import formIllus from './resources/undraw_Mailbox_re_dvds (1).png';
import './css/success.styles.css';

export default function FormSuccess() {

    return (
        <div className="success">
            <div className='success-content'>
                <h1 className="success-h1">Message Received!</h1>
                <div className='success-illus-container'>
                    <img className='success-illus' src={formIllus}></img>
                </div>
                <h3 className='thankyou'>Thank You for Submitting Your Application</h3>
                <div className='success-text'>
                    <p>Hello! We have received your application and are diligently processing
                        it. </p>
                    <p>Thank you so much for reaching out and putting your trust in us! We
                        will respond shortly with questions to determine how we can help.</p>

                    <p>Thank you,
                        The SPTV Foundation</p>

                        <p><h3>Important Notice:</h3>

If you are in immediate danger or experiencing a life-threatening emergency, please call 911 or visit your nearest emergency room.

For emotional crises or suicidal thoughts, contact the Suicide & Crisis Lifeline at 988, available 24/7.

Please note, we do not provide emergency services. If you require immediate assistance, we strongly encourage you to seek professional help.

</p>
                </div>
            </div>
        </div>
    )
}