import "./css/donate.styles.css";
import { useState } from 'react';
import { createCheckoutSession } from "../utils/StripeFunctions";
export default function Onetime () {
    const [donationAmount, setDonationAmount] = useState(0);
    console.log(donationAmount);
    const [note, setNote] = useState("");
    const [postName, setPostName] = useState(false);
    const [postAmount, setPostAmount] = useState(false);
    const getDonationAmount = (e) => {
        setDonationAmount(e.target.id);
        e.target.style.backgroundColor = "#FFCF0C";
    };

    const finalDonation = async () => {
    
        const formData ={
            donation: donationAmount,
            note: note,
            postname: postName,
            postamount: postAmount
        };
        // payload sequence formData > productName > amount
        await createCheckoutSession(formData)
            .then(session => {
                console.log('Checkout session created:', session);
                // Redirect user to checkout session URL
                window.location.href = session.url;
            })
            .catch(error => {
                console.error('Error:', error);
            });
        }
    return (
        <div className="onetime">
        <div className="first-row-db">
                        <button className="donate-button" id="5" type="button" onClick={getDonationAmount}>$5</button>
                        <button className="donate-button" id="10" type="button" onClick={getDonationAmount}>$10</button>
                        <button className="donate-button" id="20" type="button" onClick={getDonationAmount}>$20</button>
                        <button className="donate-button" id="40" type="button" onClick={getDonationAmount}>$40</button>
                    </div>
                    <div className="second-row-db">
                        <button className="donate-button" id="50" type="button" onClick={getDonationAmount}>$50</button>
                        <button className="donate-button" id="100" type="button" onClick={getDonationAmount}>$100</button>
                        <button className="donate-button" type="button" >$ <input onChange={(e) => setDonationAmount(e.target.value)} className="dono-input"></input></button>
                    </div>
                    <br></br>
                    <label>Would you like to leave a note?</label>
                    <br></br>
                    <textarea rows="20" cols="20" onChange ={(e)=> {setNote(e.target.value)}}></textarea>
                    <br></br>
                    <div className="checkbox">
                        <div>
                            <label>Post my name</label>
                            <input type="checkbox" size="30" onClick ={(e)=>{setPostName(true)}} className="check"></input>
                        </div>
                        <div>
                            <label>Post my donation amount</label>
                            <input type="checkbox" onClick ={(e)=>{setPostAmount(true)}} className="check"></input>
                        </div>
                    </div>
                    <button className="donate-button" id="donate" type="button" onClick={finalDonation}>DONATE</button>
                    </div>
    )
}