import "./css/header.styles.css";
import logo from "./resources/1 (4).png";
import { Link } from "react-router-dom";
import emailIconTiny from './resources/email-tiny.png';
import phoneIconTiny from './resources/phone-tiny.png';

export default function Header() {
  return (
    <header className="header">

      <Link to="/">
        <img src={logo} id="logo" alt="SPTV Foundation Logo"></img>
      </Link>

      <div className="header-contact">
        <div className="header-contact-container">
          <div className="header-icon-container"><img src={emailIconTiny} alt="Email Icon"></img></div>
          <a href="mailto:contact@thesptvfoundation.org" className="phoneemail">contact@thesptvfoundation.org</a>
        </div>

        <div className="header-contact-container">
          <div className="header-icon-container"><img src={phoneIconTiny} alt="Phone Icon"></img></div>
          <a href="tel:727-266-5797" className="phoneemail">727-266-5797</a>
        </div>
      </div>

      <nav className="navigation">
        <ul>
          <li className="nav-item"><a href="/about" className="nav-link">About</a></li>
          <li className="nav-item"><a href="/donate" className="nav-link">Donate</a></li>
          <li className="nav-item"><a href="/gethelp" className="nav-link">Get Help</a></li>
          {/* <li  className="nav-item"><a href="/learn" className="nav-link">Learn</a></li> */}
        </ul>
      </nav>

    </header>
  );
}