import successIllus from './resources/undraw_Partying_re_at7f.png';
import './css/success.styles.css';

export default function Success () {

    return (
        <div className="success">
            <div className='success-content'>
            <h1 className="success-h1">Success!</h1>
            <div className='success-illus-container'>
                <img className='success-illus' src= {successIllus}></img>
            </div>
            <h3 className='thankyou'>Thank You for Your Generous Donation!</h3>
            <div className='success-text'>
            <p>We are incredibly grateful for your support. Your donation will make a meaningful difference and help us help those who are leaving or have left Scientology. With your contribution, 
                we can continue our efforts to empower and assist victims of Scientology.</p>
                <p>Your kindness and generosity inspire us every day. Together, we are making a positive change in the world.</p>
                <p>Once again, thank you for your donation. We couldn't do it without you!</p>
                <p>Warm regards,
The SPTV Foundation</p>
</div>
        </div>
        </div>
    )
}