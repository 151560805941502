
import "./css/header.styles.css";
import emailIcon from './resources/email-48.png';
import phoneIcon from './resources/phone-46-48.png';

export default function Footer() {
  return (
    <footer className="footer">
      <p className="footer-para">Every day is a great day NOT to be in a cult.</p>
      <div className="contact">
        <div className="contact-container">
          <div className="icon-container"><img src={emailIcon} alt="Email Icon" /></div>
          <a href="mailto:contact@thesptvfoundation.org" className="phoneemail">contact@thesptvfoundation.org</a>
        </div>
        <div className="contact">
          <div className="contact-container">
            <div className="icon-container"><img src={phoneIcon} alt="Phone Icon" /></div>
            <a href="tel:727-266-5797" className="phoneemail">727-266-5797</a>
          </div>
        </div>
        <div className="contact">
          <div className="contact-container">
            <div className="icon-container"><img src={emailIcon} alt="Email Icon" /></div>
            <a href="mailto:volunteer@thesptvfoundation.org" className="phoneemail">volunteer@thesptvfoundation.org</a>
          </div>
        </div>
      </div>
      <div className="alt-nav">
        <ul>
          <li className="nav-item"><a href="/about" className="nav-link">ABOUT</a></li>
          <li className="nav-item"><a href="/donate" className="nav-link">DONATE</a></li>
          <li className="nav-item"><a href="/gethelp" className="nav-link">GET HELP</a></li>
          {/* <li  className="nav-item"><a href="/learn" className="nav-link">Learn</a></li> */}
        </ul>
      </div>
    </footer>
  );
}
