import "./css/donate.styles.css";
import { useState } from 'react';
import { createCheckoutSession } from "../utils/StripeFunctions";
import firstImage from "./resources/undraw_walking_in_rain_vo9p (1).png";
import secondImage from "./resources/undraw_stepping_up_g6oo.png";
import thirdImage from "./resources/undraw_Lives_matter_38lv (3).png";
import Onetime from './one-time.js';
import Recurring from "./recurring.js";
// import Recurring from './recurring.js';


export default function Donate() {
    const [donationAmount, setDonationAmount] = useState(0);
    console.log(donationAmount);
    const [note, setNote] = useState("");
    const [postName, setPostName] = useState(false);
    const [postAmount, setPostAmount] = useState(false);
    const [donoType, setDonoType] = useState(false);
    // const [donateClicked, setDonateClicked] = useState(0);
    const getDonationAmount = (e) => {
        setDonationAmount(e.target.id);
        e.target.style.backgroundColor = "#FFCF0C";
    };

    const finalDonation = async () => {

        const formData = {
            donation: donationAmount,
            note: note,
            postname: postName,
            postamount: postAmount
        };
        // payload sequence formData > productName > amount
        await createCheckoutSession(formData)
            .then(session => {
                console.log('Checkout session created:', session);
                // Redirect user to checkout session URL
                window.location.href = session.url;
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    return <div className="donate">
       
        <div className="content">
        <a href="#donate-box" className="donate-now">Donate Now</a>
      
            <div className="why">
                <h1 className="donate-h1">Why We Do This</h1>
                <div className="first-row">
                    <div className="paragraph">
                        <p className="donate-para">When people leave Scientology, they often find themselves without a support system. Due to Scientology’s policy of disconnection,
                            individuals leaving the organization are cut off from their family, friends, and essentially everyone they have known for the vast majority of their life.
                            Scientology has staff dedicated to monitoring these connections closely, even policing social media and encouraging members to report on others lest they too get into trouble.
                        </p>
                        <br></br>
                        <p className="donate-para">Because of this disconnection policy, it can be difficult for individuals to find work, housing, healing, and a community. After a lifetime of trauma and indoctrination,
                            it often takes time to adjust to mainstream society while processing everything they have been through and rethinking concepts they have believed to be true their entire lives.</p>
                    </div>
                    <div className="image-container">
                        <img src={firstImage} alt="Color illustration of a man walking alone looking somber"></img>
                    </div>
                </div>
                <div className="second-row">
                    <div className="image-container">
                        <img src={secondImage} alt="Color illustration of a man ascending stairs"></img>
                    </div>
                    <div className="paragraph">
                        <p className="second-para-why donate-para">
                            Even many years after leaving, members are still processing the side effects of extreme childhood neglect, sexual abuse,
                            lack of education, physical abuse, mental manipulation, and extreme overwork. None of this dissipates the moment they walk out the door.
                            It takes years to process what they have been through and to learn healthy coping mechanisms.
                        </p>
                        
                        <p className="second-para-why donate-para">We are here to support this process.</p>

                        
                    </div>
                    
                </div>
                <a href="#donate-box" className="donate-now">Donate Now</a>
            </div>
            <div className="support">
                <div className="support-content">
                    <h1 className="support-sptv-h1">Support the SPTV Foundation!</h1>
                    <div className="support-intro-top">
                        <div className="support-intro">
                            <div className="third-img-container">
                                <img src={thirdImage} alt="Color illustration of a group comprised of men and women holding hands looking joyful"></img>
                            </div>
                            <p className="first-para-support donate-para">We are so grateful for our many subscribers and followers of SPTV who have gone out of their way over the years to improve the lives of individuals affected by Scientology.
                                Words cannot describe how life affirming it is when someone who was never involved in Scientology cares enough about those who were and does something big or small to make life a little bit easier.
                                We thank you from the bottom of our hearts.</p>
                        </div>
                    </div>

                    <div className="donate-box-section">
                        <p className="donate-para">To continue making a meaningful difference, we ask you to contribute to our efforts by making a donation. Your support enables us to provide crucial assistance, support programs, and resources to those who need it most.
                            Every little bit helps, and your contribution can directly impact someone's journey to healing and recovery.
                        </p>
                        <p className="donate-para">Thank you for considering and actively joining us in our commitment to make a positive impact. We appreciate your support and truly value your role in our community!</p>
                    </div>
                </div>
                <div className="donate-box" id="donate-box">

                    <form>

                        <label htmlFor="donationType" id="donoSelect">Select Donation Type</label>
                        <select id="donationType" name="donationType" onChange={(e) => { setDonoType(e.target.value) }}>
                            <option value="" disabled selected>Select</option>
                            <option value="recurring">Recurring</option>
                            <option value="one-time">One-time</option>
                        </select>

                        {
                            donoType === "one-time" ? <Onetime /> : donoType === "recurring" ? <Recurring /> : null
                        }
                        <p className="paypal">If you do not have a credit card and would like to donate using Paypal, <a className="paypal"href="https://www.paypal.com/donate/?hosted_button_id=A3DRT3E48JYL4">please click here</a></p>

                    </form>
                </div>

            </div>

        </div>
    </div>
}