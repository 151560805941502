import teamIllus from './resources/undraw_Team_collaboration_re_ow29.png';
import './css/about.styles.css';
import jennaPhoto from './resources/jenna.jpg';

import aaronPhoto from './resources/aaron.jpg';
import mikePhoto from './resources/mike.jpg';

import nataliePhoto from './resources/natalie.jpg';
import zacPhoto from './resources/zac.jpg';
import georgePhoto from './resources/george.jpg';
import reesePhoto from './resources/reese.jpg';
import bylaws from './resources/bylaws.pdf';
import grantAward from './resources/SPTV Grant Award Notification - FOR WEBSITE.pdf';
export default function About() {
    const teamList = [{ name: "Aaron Smith-Levin", title: "President", photo: aaronPhoto, yt: "https://www.youtube.com/@GrowingUpInScientology" }, { name: "Mike Brown", title: "Secretary", photo: mikePhoto, yt: "https://www.youtube.com/@MikeBrown101" }, { name: "Natalie Webster", title: "Treasurer", photo: nataliePhoto, yt: "https://www.youtube.com/@NatalieWebsterExSeaOrg" },
    { name: "Zac Morgan", title: "Board Member", photo: zacPhoto, yt: "https://www.youtube.com/@YourLawyerFriendZac" }, { name: "Jenna Miscavige", title: "Board Member", photo: jennaPhoto, yt: "https://www.youtube.com/channel/UCQKFF3F2P0enb5RUybSvQAQ" },
{ name: "George LaBanca", title: "Board Member", photo: georgePhoto, yt: "https://www.youtube.com/@SurroundedBySci" }, 
    { name: "Reese Quibell", title: "Board Member", photo: reesePhoto, yt: "https://www.youtube.com/@RelatableReese" }];


    return (

        <div className="about-page">
            <div className="about-page-content">
            <h1 className='about-h1'>Our Team</h1>
                <div className="our-team-intro">
                
                    <div className='intro-text-container'>
                        
                        <p>Our team is comprised of former Scientologists and advocates who uniquely understand the challenges of finding solid ground after leaving Scientology.</p>

                           <p>Founded by Aaron Smith-Levin in 2024, the foundation represents a diverse group of individuals affiliated with SPTV. </p>
                            <p>SPTV is a community of YouTubers dedicated to raising awareness about Scientology and highlighting its nuanced experiences and abuses.
                        </p>
                    </div>
                    <div className="about-illus-container">
                        <img src={teamIllus} alt="Colorful illustration of team members working together"></img>
                    </div>

                </div>
                <div className='our-team-text'>
                    <p>Each member of SPTV brings a unique perspective, and their diversity in personality and viewpoint is celebrated by their following.</p>
                    <p>SPTV was created to empower ex-members, providing them with a platform to share their truths without having their stories misrepresented or taken advantage of by mainstream media and journalists with their own agenda. </p>
                    <p>The SPTV Foundation adds an altruistic dimension by utilizing the SPTV platforms to raise funds for its programs.</p>
                    <p>Our <a href={bylaws}>bylaws</a> and <a href={grantAward}>grant award notification</a> are linked here.</p>
                </div>
            </div>
            <div className='about-page-content-two'>


                <div className='team-members-section'>
                    {teamList.map(member => (<div className='member-container'><a href={member.yt} title= {`${member.name}'s Youtube Channel`} className='ytlink'><div className='photo-container'>
                        <img src={member.photo} alt= {`Headshot of ${member.title}, ${member.name}`} className='member-photo'></img>
                    </div>
                        <h2>{member.name}</h2>
                        <p className='title'>{member.title}</p></a></div>

                    ))}

                </div>

            </div>
            


        </div>)

}