
import './App.css';
import Header from './components/header.js';
import Home from './components/home.js';
import Donate from './components/donate.js';
import Footer from './components/footer.js';
import About from './components/about.js';
import GetHelp from './components/gethelp.js';
import Success from './components/success.js';
import FormSuccess from './components/formsuccess.js';
import {Route, Routes} from "react-router-dom";


function App() {
  return (
    <div className='App'>
  <Header/>
      <Routes >
        
         <Route path ="/" element={<Home/>}/>
         <Route path ="/donate" element={<Donate/>}/>
         <Route path ="/about" element={<About/>}/>
         <Route path ="/gethelp" element={<GetHelp/>}/>
         <Route path ="/success" element={<Success/>}/>
         <Route path ="/formsuccess" element={<FormSuccess/>}/>
         

       
      </Routes>
      <Footer/>

</div>
  );
}

export default App;
