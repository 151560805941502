import illustration from "./resources/Illustration (10).png";
import "./css/home.styles.css";
import React from 'react';
import { Link } from 'react-router-dom';
export default function Home() {
    return <div className="homepage">
        <hero className="hero">
        <div className="intro">
            <h1 className="hero-h1">We’re here to empower</h1>
            <p className="hero-para">people who want to leave Scientology
                and those who have already left by
                offering support during their transition
                into mainstream society and beyond.</p>
                <Link to="/about">
                <button className="herobutton">LEARN MORE</button> </Link>
        </div>
        <div id="illustration-container">
        <img src={illustration}id="illustration" alt="Line drawing style illustration of a laptop showing the SPTV members on YouTube"></img>
        </div>
        </hero>
<section className="whoarewe">
    <designblock className="designblock"></designblock>
    <block className="wawtext">
    <h1>WHO ARE WE?</h1>
    <p className="section-para">We are a nonprofit organization comprised primarily of former 
Scientologists who uniquely understand how hard it can be to leave Scientology after a lifetime of trauma and indoctrination.  </p>
<Link to="/about"><button className="wawbutton">Learn More</button></Link>
</block>
</section>
<section className="questions">
    <block className="questionsblock">
    <h1>Need Help?</h1>
    <p className="section-para">Are you a former Scientologist or current Scientologist looking to leave Scientology? We’re here to help!</p>
    <Link to="/gethelp"><button className="questionsbutton">Apply</button></Link>
</block>
<block className="questionsblock">
    <h1 className="can-you-help-h1">How Can You Help?</h1>
    <p className="section-para">So glad you asked! There are so many ways to help whether it is donating funds or supporting our many programs. </p>
    <Link to="/donate"><button className="questionsbutton">Support</button></Link>
</block>
</section>
    </div>
}