import { STRIPE_SK, cancel_url, success_url } from "../config/StripeConfig";

// Function to create metadata from form data
export const createMetadataFromFormData = (formData) => {
    const metadata = {
        amount: formData.donation,
        note: formData.note,
        postamount: formData.postamount ? 'Yes' : 'No',
        postname: formData.postname ? 'Yes' : 'No',
    };

    return metadata;
};

// Function to create a checkout session one time
export const createCheckoutSession = async (formData) => {
    const metadata = createMetadataFromFormData(formData);
    console.log(metadata);
    try {
        const response = await fetch('https://api.stripe.com/v1/checkout/sessions', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${STRIPE_SK}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                'mode': 'payment',
                'payment_method_types[]': 'card',
                'line_items[][quantity]': 1,
                'line_items[][price_data][product_data][name]': 'DONATION', // Replace with your product name
                // 'lisne_items[][price_data][product_data][description]': 'Your Product Description', // Replace with your product description
                'line_items[][price_data][currency]': 'usd',
                'line_items[][price_data][unit_amount]':  metadata.amount * 100, // in cents
                'success_url': success_url,
                'cancel_url': cancel_url,
                'payment_intent_data[metadata][note]': metadata.note,
                'payment_intent_data[metadata][postamount]': metadata.postamount,
                'payment_intent_data[metadata][postname]': metadata.postname,
            }).toString()
        });

        if (!response.ok) {
            throw new Error('Error creating checkout session');
        }

        const session = await response.json();
        return session;
    } catch (error) {
        console.error('Error creating checkout session:', error);
        return null;
    }
};



//function to create checkout session recurring
export const createCheckoutSessionRecurring = async (formData) =>{
    console.log(formData);
    const metadata = {
        amount: formData.donation,
        note: formData.note,
        postamount: formData.postamount ? 'Yes' : 'No',
        postname: formData.postname ? 'Yes' : 'No',
    };
    try {
        

        const sessionParams = new URLSearchParams();
        sessionParams.append('payment_method_types[]', 'card');
        sessionParams.append('mode', 'subscription');
        sessionParams.append('line_items[][quantity]', 1);
        sessionParams.append('subscription_data[metadata][note]', metadata.note);
        sessionParams.append('subscription_data[metadata][postamount]', metadata.postamount);
        sessionParams.append('subscription_data[metadata][postname]', metadata.postname);
        sessionParams.append('line_items[][price_data][product_data][name]', 'SPTV Foundation Recurring Donation');
        sessionParams.append('line_items[][price_data][currency]', 'usd');
        sessionParams.append('line_items[][price_data][unit_amount]', metadata.amount * 100); // Convert amount to cents
        sessionParams.append('line_items[][price_data][recurring][interval]', formData.interval);
        sessionParams.append('success_url', success_url);
        sessionParams.append('cancel_url', cancel_url);

        const response = await fetch('https://api.stripe.com/v1/checkout/sessions', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${STRIPE_SK}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: sessionParams
        });

        if (!response.ok) {
            throw new Error('Failed to create checkout session');
        }

        const responseData = await response.json();
        return responseData.url; // Return the session URL
    } catch (error) {
        console.error('Error creating checkout session:', error);
        throw error;
    }
}



